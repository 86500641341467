import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {
  FetchProductsStore,
  ProductGroupsStore,
} from '@em/data-feed/data-access-products';
import {
  ContractStore,
  RepricingProductsCountStore,
} from '@em/subscription/data-access';
import {
  DatafeedStore,
  PipelineListStore,
} from '@em/data-feed/data-access-setup';
import {SharedModule} from './shared-module/shared.module';
import {WINDOW} from '@em/shared/util-web';
import {
  NotificationsFeatureModule,
  PUSHER_AUTH_ENDPOINT,
} from '@em/server-notifications';
import {
  LocalStorageDriver,
  STORAGE_DRIVER,
  TokenStorageService,
} from '@em/auth/data-access';
import {EmButtonModule} from '@em/shared/ui';
import {
  APP_ROUTES,
  configurationHelper,
  IS_PRODUCTION_MODE,
} from '@em/shared/util-configuration';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {environment} from '../environments/environment';
import {AppErrorComponent} from './app-error.component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LOCALE_SETTING} from './app.l10n';
import {providePusherAuthEndpoint} from './provide-pusher-auth-endpoint/provide-pusher-auth-endpoint';
import {AppRoutes} from './app-routes/app-routes';
import {JwtOptionsFactory} from './auth0-config/jwt-options-factory';
import {provideComponentStore} from '@ngrx/component-store';
import {ManageGroupsService} from '@em/data-feed/feature-products';
import {ManagedCampaignsStore} from '@em/campaigns/data-access';
import {getInitialState, initialReducerMap} from './app-state/app.state';
import {ThemingDirective} from '@em/shared/white-label';
import {NavModule} from './navigation/nav.module';
import {OnboardingModule} from '@em/onboarding/feature';
import {SideNavigationModule} from './navigation/side-navigation/side-navigation.component';
import {AppEffects} from '@em/auth/data-access';
import {MatPaginatorIntlService} from './ui-config/mat-paginator-intl.service';
import {GetHttpLoaderFactory} from '@em/shared/util-i18n';

@NgModule({
  declarations: [AppComponent, ThemingDirective, AppErrorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(initialReducerMap, {initialState: getInitialState}),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: configurationHelper.getConfig('RACK_ENV') === 'production',
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
    }),
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: GetHttpLoaderFactory('./assets/i18n/'),
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: JwtOptionsFactory,
        deps: [TokenStorageService],
      },
    }),

    SharedModule.forRoot(),
    NotificationsFeatureModule.forRoot(),
    NavModule,
    AppRoutingModule,
    OnboardingModule,
    SideNavigationModule,
    EmButtonModule,
    GoogleTagManagerModule.forRoot({
      id: configurationHelper.getConfig('GOOGLE_TAG_MANAGER_ID') as string,
    }),
  ],
  providers: [
    {
      provide: STORAGE_DRIVER,
      useFactory: () => new LocalStorageDriver(window.localStorage),
    },
    {provide: ErrorHandler, useValue: Sentry.createErrorHandler()},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlService},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'},
    },
    {provide: LOCALE_ID, useValue: LOCALE_SETTING.current},
    {provide: WINDOW, useValue: window},
    DatafeedStore,
    PipelineListStore,
    FetchProductsStore,
    RepricingProductsCountStore,
    provideComponentStore(ProductGroupsStore),
    provideComponentStore(ManagedCampaignsStore),
    provideComponentStore(ContractStore),
    ManageGroupsService,
    {provide: PUSHER_AUTH_ENDPOINT, useFactory: providePusherAuthEndpoint},
    {
      provide: IS_PRODUCTION_MODE,
      useValue: environment.production,
    },
    {provide: APP_ROUTES, useValue: AppRoutes},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
