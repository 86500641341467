import {API_DOMAIN_SETTINGS_LIST} from '../http-interceptors/api-domain-settings-list';

export const providePusherAuthEndpoint = (): string => {
  const emarketingApiSettings = API_DOMAIN_SETTINGS_LIST.find(
    (settings) => settings.name === 'emarketing',
  );

  if (!emarketingApiSettings) {
    return '';
  } else {
    return (
      emarketingApiSettings.serviceDomain +
      '/emarketing_api/notifications/authorize_channel'
    );
  }
};
