import {configurationHelper} from '@em/shared/util-configuration';
import {ApiDomainSettings} from './api-domain-settings';

export const API_DOMAIN_SETTINGS_LIST: ApiDomainSettings[] = [
  {
    name: 'repricing',
    serviceDomain: configurationHelper.needConfig('REPRICING_GATEWAY_URL'),
    apiPrefix: 'api',
  },
  {
    name: 'emarketing',
    serviceDomain: configurationHelper.needConfig('EMARKETING_GATEWAY_URL'),
    apiPrefix: 'emarketing_api',
  },
  {
    name: 'shop-system',
    serviceDomain: configurationHelper.needConfig('SHOPSYSTEM_GATEWAY_URL'),
    apiPrefix: 'configuration_api',
  },
  {
    name: 'market_insights',
    serviceDomain: configurationHelper.needConfig(
      'MARKET_INSIGHTS_GATEWAY_URL',
    ),
    apiPrefix: 'market_insights_api',
    replacePrefixWith: 'configuration_api',
  },
  {
    name: 'payment',
    serviceDomain: configurationHelper.needConfig('PAYMENT_GATEWAY_URL'),
    apiPrefix: 'payment_api',
    replacePrefixWith: 'configuration_api',
  },
];
